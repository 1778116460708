import * as VueRouter from 'vue-router';

import Home from './views/Home.vue'
import Error from './views/Error.vue'
import Default from './views/Default.vue'
import Category from './views/Category.vue'
import About from './views/About.vue'
import Artist from './views/Artist.vue'
import Artists from './views/Artists.vue'
import Projects from './views/Projects.vue'
import Project from './views/Project.vue'
import Reel from './views/Reel.vue'

const routes = [
  { path: '/home', redirect: '/' },
  { name: 'Error', path: '/error', component: Default },
  { name: 'Home', path: '/', component: Home },
  { name: 'About', path: '/about', component: About },
  { name: 'Artists', path: '/artists', component: Artists },
  { name: 'Artist', path: '/artists/:primi', component: Artist },
  { name: 'Projects', path: '/projects', component: Projects },
  { name: 'Project', path: '/projects/:primi', component: Project },
  { name: 'Reel', path: '/reels/:primi', component: Reel },
  { name: 'Default', path: '/:primi', component: Category },
]

export const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes: routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
  }
})
