//YAML Helpers
import yaml from 'js-yaml';
import { isProxy, toRaw, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import gsap from 'gsap';

export const routeStore = defineStore('routes', () => {
  const route = useRoute();
  const visitedURLs = ref([])
  watch(route, (route) => visitedURLs.value.unshift(route.path));
  return {route, visitedURLs};
});

export const styleStore = defineStore('styles', () => {
  const main = ref({
        'background-color': 'black',
        'color': 'white' });
  const header = ref({'y': '0rem'});
  const transition = ref({'duration': '0.5s'});
  const goWhite = () => {
    const options = {
      color: '#000000',
      'background-color' : '#ffffff',
      ease: 'power1.inOut', // Easing function
      //delay: transition.value.duration,
      duration: parseFloat(transition.value.duration)  // Duration in seconds
    }
    gsap.to(main.value, options);
    gsap.to('body', options);
  };

  const noTouch = () => {
    document.body.style.touchAction = 'none';
  };

  const resetTouch = () => {
    document.body.style.touchAction = 'auto';
  };

  const goBlack = () => {
    const options = {
      color: '#ffffff',
      'background-color' : '#000000',
      ease: 'power1.inOut', // Easing function
      //delay: transition.value.duration,
      duration: parseFloat(transition.value.duration) // Duration in seconds
    }
    gsap.to(main.value, options);
    gsap.to('body', options);
  };
  return {main, header, transition, goWhite, goBlack, noTouch, resetTouch};
});

export const yamlToObject = (string) => yaml.load(string);
export const yamlFirst = (string) => string ? yaml.load(string)[0].trim() : false;

//JSON Helpers
export const cleanUpJSON = (input) => {return JSON.parse(JSON.stringify(input))};

export const objectToArray = (proxyObject) => {return toRaw(proxyObject)};


//URL to PATH
export const pathName = function(input) {
  let url = new URL(input);
  return url.pathname;
}

//URL to PATH
export const files = function(filesObject) {
  let filesByFileName = [];
  for(const key of Object.keys(filesObject)) {
    filesByFileName[filesObject[key].filename] = filesObject[key]
  };
  return filesByFileName;
}

//Pages by uid
export const pages = function(pagesObject) {
  let returnArray = [];
  //Universal loop
  const getByKey = (customKey = 'uid') => {
    let tempArray = [];
    Object.keys(pagesObject).map((key)=>{tempArray[pagesObject[key][customKey]] = pagesObject[key]});
    return tempArray;
  };
  return {
    by: function(customKey = 'uid') {
      return getByKey(customKey);
    },
    is: function(key, customKey = 'uid') {
      return getByKey(customKey)[key];
    },
    fromList: function(keys, customKey = 'id') {
      keys.map( (key)=> {returnArray.push(getByKey(customKey)[key])})
      return toRaw(returnArray);
    }
  }
}

//Seconds to minutes
export const secondsToDigitalDisplay = (seconds) => {
  var minutes=0;
  var output = '00:00';
  if(seconds > 0) output = ((minutes = ((seconds-(seconds%=60))/60)) < 9 ? '0'+ minutes : minutes)+(9<seconds?':':':0')+Math.round(seconds);
  return output
}

//Get alternative language
export const alternativeLanguage = function(i) {
  const linkElementForAlternate = document.head.querySelector('link[rel=alternate]');
  return linkElementForAlternate ? linkElementForAlternate.attributes.hreflang.value : false;
}

//Get image from files object by id
export const imageById = function(images, filename) {
  filename = filename.includes('/') ? filename.split('/').pop() : filename;
  const imageObject = images.filter( image => (image.filename == filename))[0];
  if(images){
    return objectToArray(imageObject);
  } else {
    console.error('No images');
  }
}

//Local images
export const srcImage = ((url) => {
  return new URL(`/src/${url}`, import.meta.url).href;
});

//Local images
export const publicImage = ((url) => {
  return new URL(`/public/${url}`, import.meta.url).href;
});

//Get page object from path
export const pageFromPath = (path, pages) => {
  if (path, pages) {
    var slugs = path.trim().split('/');
    var page = false;
    var children = pages;
    while (slugs.length) {
      var id = slugs.shift();
      page = children.find(o => o.uid === id);
      if (page)
        children = typeof page.children === 'object' ? page.children : [];
    }
    if (page) {
      return page;
    } else {
      console.error('No page!', path, pages);
    }
  } else {
    console.error('No pages! Add a page element with function', path, pages);
  }
}

//Get page objects with list of paths
export const pagesFromPaths = function(list, pages) {
  if(list !='undefined' && pages.length) {
    let self = this;
    let paths = (typeof list == 'array') ? list : Object.values(list);
    let returnArray = [];
    paths.map(function(path){
      let page = pageFromPath(path, pages);
      if(page) returnArray.push(page);
    });
    //console.log( Array.isArray(returnArray));
    return returnArray;
  }
}
