import './index.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { router } from './router.js'
import App from './App.vue'
import VueSmoothScroll from 'vue3-smooth-scroll'

//Constants
const pinia = createPinia()
const app = createApp(App)

//Use
app.use(pinia)
app.use(router)
app.use(VueSmoothScroll)

import Vue3TouchEvents from "vue3-touch-events";
app.use(Vue3TouchEvents);

//Mount
app.mount('#app')
